// import markdownParser from '@nuxt/content/transformers/markdown'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.config.globalProperties.$markdown = (body) => {
        return body
        // const parsedMarkdown = await markdownParser.parse(
        //     'custom',
        //     body
        // )
        //
        // return parsedMarkdown
    }
})
