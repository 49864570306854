import HttpFactory from './Factory/HttpFactory'

class CommentRepository extends HttpFactory {
    index (model, modelId, params = {}) {
        return this.$get(`/${model}/${modelId}/comments`, params)
    }

    show (model, modelId, commentId) {
        return this.$get(`${model}/${modelId}/comments/${commentId}`)
    }

    create (model, modelId, payload) {
        return this.$post(`${model}/${modelId}/comments`, payload)
    }

    update (model, modelId, commentId, payload) {
        return this.$put(`${model}/${modelId}/comments/${commentId}`, payload)
    }

    delete (model, modelId, commentId) {
        return this.$delete(`${model}/${modelId}/comments/${commentId}`)
    }
}

export default CommentRepository
