import HttpFactory from './Factory/HttpFactory'

class StatsRepository extends HttpFactory {
    index (params) {
        return this.$get('/stats', params)
    }

    clientRevenueSecured (clientId) {
        return this.$get(`/stats/clients/${clientId}/amount-secured`)
    }

    clientRevenueInvoiced (clientId) {
        return this.$get(`/stats/clients/${clientId}/amount-invoiced`)
    }

    clientRevenuePaid (clientId) {
        return this.$get(`/stats/clients/${clientId}/amount-paid`)
    }

    clientRevenueTargetted (clientId) {
        return this.$get(`/stats/clients/${clientId}/amount-targetted`)
    }
}

export default StatsRepository
