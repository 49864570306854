import HttpFactory from './Factory/HttpFactory'

class FolderRepository extends HttpFactory {
    getForClient (clientId) {
        return this.$get(`clients/${clientId}/folders`)
    }

    getForSettings () {
        return this.$get('settings/folders')
    }

    index (params = {}) {
        return this.$get('folders', params)
    }

    show (folderId) {
        return this.$get('folders/' + folderId)
    }

    create (payload) {
        return this.$post('folders', payload)
    }

    update (folderId, payload) {
        return this.$put(`folders/${folderId}`, payload)
    }

    delete (folderId, params) {
        return this.$delete(`folders/${folderId}`, params)
    }

    restore (folderId, params) {
        return this.$post(`folders/${folderId}/restore`, params)
    }

    download (folderId) {
        return this.$post(`/folders/${folderId}/download`)
    }

    export (folderId) {
        return this.fetch(`/folders/${folderId}/export`, {
            method: 'post',
            responseType: 'blob'
        })
    }

    uploadFiles (folderId, payload) {
        return this.$post(`folders/${folderId}/medias`, payload)
    }

    getUsers (folderId) {
        return this.$get(`folders/${folderId}/users`)
    }

    attachUser (folderId, payload) {
        return this.$post(`folders/${folderId}/users`, payload)
    }

    removeUser (folderId, userId) {
        return this.$delete(`folders/${folderId}/users/${userId}`)
    }
}

export default FolderRepository
