import { useAuthStore } from '~/stores/auth'
import { defineNuxtRouteMiddleware, navigateTo } from '#imports'

export default defineNuxtRouteMiddleware((to) => {
    const auth = useAuthStore()

    if ((!auth.authenticated || !auth.user) && to.meta.layout !== 'auth') {
        auth.logout()
        return navigateTo('/login')
    }
})
