import { default as indexRg1V7wzL59Meta } from "/home/ploi/app.kray.eu/pages/Acquisition/index.vue?macro=true";
import { default as indexNADq1DqV3vMeta } from "/home/ploi/app.kray.eu/pages/Bank/[id]/contact/index.vue?macro=true";
import { default as indexBFEv9w5EXtMeta } from "/home/ploi/app.kray.eu/pages/Bank/[id]/index.vue?macro=true";
import { default as createczcteGhR20Meta } from "/home/ploi/app.kray.eu/pages/Bank/create.vue?macro=true";
import { default as indexFMAgjzo4kKMeta } from "/home/ploi/app.kray.eu/pages/Bank/index.vue?macro=true";
import { default as editZ06yXaPNIfMeta } from "/home/ploi/app.kray.eu/pages/Broker/[id]/edit.vue?macro=true";
import { default as indexF6B9lhXmnrMeta } from "/home/ploi/app.kray.eu/pages/Broker/[id]/index.vue?macro=true";
import { default as createw2XS8LSjrjMeta } from "/home/ploi/app.kray.eu/pages/Broker/create.vue?macro=true";
import { default as index5z2FiWy9PIMeta } from "/home/ploi/app.kray.eu/pages/Broker/index.vue?macro=true";
import { default as edity8ojF3xNqLMeta } from "/home/ploi/app.kray.eu/pages/Client/[id]/edit.vue?macro=true";
import { default as indexPrb28tHcmzMeta } from "/home/ploi/app.kray.eu/pages/Client/[id]/index.vue?macro=true";
import { default as createULULZhZhqVMeta } from "/home/ploi/app.kray.eu/pages/Client/create.vue?macro=true";
import { default as indextKEvmUMsIPMeta } from "/home/ploi/app.kray.eu/pages/Client/index.vue?macro=true";
import { default as editICjWQNE4HBMeta } from "/home/ploi/app.kray.eu/pages/Contact/[contact_id]/edit.vue?macro=true";
import { default as indexNtxWVI2c1DMeta } from "/home/ploi/app.kray.eu/pages/Contact/[contact_id]/index.vue?macro=true";
import { default as indexkVKbts2ZznMeta } from "/home/ploi/app.kray.eu/pages/Contact/index.vue?macro=true";
import { default as indexoAXUoUyIQ4Meta } from "/home/ploi/app.kray.eu/pages/Documents/index.vue?macro=true";
import { default as indexNIxw4RxOysMeta } from "/home/ploi/app.kray.eu/pages/FunderCompany/[id]/contact/index.vue?macro=true";
import { default as editZckKqXdQkIMeta } from "/home/ploi/app.kray.eu/pages/FunderCompany/[id]/edit.vue?macro=true";
import { default as indexAAvSvaZnEFMeta } from "/home/ploi/app.kray.eu/pages/FunderCompany/[id]/index.vue?macro=true";
import { default as createoKZrXvxkvZMeta } from "/home/ploi/app.kray.eu/pages/FunderCompany/create.vue?macro=true";
import { default as index6NfvGAR1iwMeta } from "/home/ploi/app.kray.eu/pages/FunderCompany/index.vue?macro=true";
import { default as indexgPIOmQqb2nMeta } from "/home/ploi/app.kray.eu/pages/index.vue?macro=true";
import { default as indexvh6Hu74PnLMeta } from "/home/ploi/app.kray.eu/pages/Login/index.vue?macro=true";
import { default as tokenQQmSy3Be8EMeta } from "/home/ploi/app.kray.eu/pages/Login/token.vue?macro=true";
import { default as logout7uNl6FiZqdMeta } from "/home/ploi/app.kray.eu/pages/logout.vue?macro=true";
import { default as editAAcOyspWKjMeta } from "/home/ploi/app.kray.eu/pages/PartnerCompany/[id]/edit.vue?macro=true";
import { default as indexzpoEDr63SVMeta } from "/home/ploi/app.kray.eu/pages/PartnerCompany/[id]/index.vue?macro=true";
import { default as createsum7YMYFCZMeta } from "/home/ploi/app.kray.eu/pages/PartnerCompany/create.vue?macro=true";
import { default as indexXGzaIlgtRdMeta } from "/home/ploi/app.kray.eu/pages/PartnerCompany/index.vue?macro=true";
import { default as indexztX4VAxaBfMeta } from "/home/ploi/app.kray.eu/pages/Product/[id]/contact/index.vue?macro=true";
import { default as indexDOoPB7TDQTMeta } from "/home/ploi/app.kray.eu/pages/Product/[id]/index.vue?macro=true";
import { default as createUhrKPTB5QZMeta } from "/home/ploi/app.kray.eu/pages/Product/create.vue?macro=true";
import { default as indexXDfE6pmD1XMeta } from "/home/ploi/app.kray.eu/pages/Product/index.vue?macro=true";
import { default as indexyIrbA4B5RHMeta } from "/home/ploi/app.kray.eu/pages/Prospection/index.vue?macro=true";
import { default as indexUGHXox47l1Meta } from "/home/ploi/app.kray.eu/pages/Settings/index.vue?macro=true";
import { default as indexdZ7fNoOOasMeta } from "/home/ploi/app.kray.eu/pages/Task/index.vue?macro=true";
import { default as indexAlnvb9efyyMeta } from "/home/ploi/app.kray.eu/pages/Timesheet/index.vue?macro=true";
export default [
  {
    name: "Acquisition",
    path: "/Acquisition",
    component: () => import("/home/ploi/app.kray.eu/pages/Acquisition/index.vue")
  },
  {
    name: "Bank-id-contact",
    path: "/Bank/:id()/contact",
    component: () => import("/home/ploi/app.kray.eu/pages/Bank/[id]/contact/index.vue")
  },
  {
    name: "Bank-id",
    path: "/Bank/:id()",
    component: () => import("/home/ploi/app.kray.eu/pages/Bank/[id]/index.vue")
  },
  {
    name: "Bank-create",
    path: "/Bank/create",
    component: () => import("/home/ploi/app.kray.eu/pages/Bank/create.vue")
  },
  {
    name: "Bank",
    path: "/Bank",
    component: () => import("/home/ploi/app.kray.eu/pages/Bank/index.vue")
  },
  {
    name: "Broker-id-edit",
    path: "/Broker/:id()/edit",
    component: () => import("/home/ploi/app.kray.eu/pages/Broker/[id]/edit.vue")
  },
  {
    name: "Broker-id",
    path: "/Broker/:id()",
    component: () => import("/home/ploi/app.kray.eu/pages/Broker/[id]/index.vue")
  },
  {
    name: "Broker-create",
    path: "/Broker/create",
    component: () => import("/home/ploi/app.kray.eu/pages/Broker/create.vue")
  },
  {
    name: "Broker",
    path: "/Broker",
    component: () => import("/home/ploi/app.kray.eu/pages/Broker/index.vue")
  },
  {
    name: "Client-id-edit",
    path: "/Client/:id()/edit",
    component: () => import("/home/ploi/app.kray.eu/pages/Client/[id]/edit.vue")
  },
  {
    name: "Client-id",
    path: "/Client/:id()",
    component: () => import("/home/ploi/app.kray.eu/pages/Client/[id]/index.vue")
  },
  {
    name: "Client-create",
    path: "/Client/create",
    component: () => import("/home/ploi/app.kray.eu/pages/Client/create.vue")
  },
  {
    name: "Client",
    path: "/Client",
    component: () => import("/home/ploi/app.kray.eu/pages/Client/index.vue")
  },
  {
    name: "Contact-contact_id-edit",
    path: "/Contact/:contact_id()/edit",
    component: () => import("/home/ploi/app.kray.eu/pages/Contact/[contact_id]/edit.vue")
  },
  {
    name: "Contact-contact_id",
    path: "/Contact/:contact_id()",
    component: () => import("/home/ploi/app.kray.eu/pages/Contact/[contact_id]/index.vue")
  },
  {
    name: "Contact",
    path: "/Contact",
    component: () => import("/home/ploi/app.kray.eu/pages/Contact/index.vue")
  },
  {
    name: "Documents",
    path: "/Documents",
    component: () => import("/home/ploi/app.kray.eu/pages/Documents/index.vue")
  },
  {
    name: "FunderCompany-id-contact",
    path: "/FunderCompany/:id()/contact",
    component: () => import("/home/ploi/app.kray.eu/pages/FunderCompany/[id]/contact/index.vue")
  },
  {
    name: "FunderCompany-id-edit",
    path: "/FunderCompany/:id()/edit",
    component: () => import("/home/ploi/app.kray.eu/pages/FunderCompany/[id]/edit.vue")
  },
  {
    name: "FunderCompany-id",
    path: "/FunderCompany/:id()",
    component: () => import("/home/ploi/app.kray.eu/pages/FunderCompany/[id]/index.vue")
  },
  {
    name: "FunderCompany-create",
    path: "/FunderCompany/create",
    component: () => import("/home/ploi/app.kray.eu/pages/FunderCompany/create.vue")
  },
  {
    name: "FunderCompany",
    path: "/FunderCompany",
    component: () => import("/home/ploi/app.kray.eu/pages/FunderCompany/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgPIOmQqb2nMeta || {},
    component: () => import("/home/ploi/app.kray.eu/pages/index.vue")
  },
  {
    name: "Login",
    path: "/Login",
    meta: indexvh6Hu74PnLMeta || {},
    component: () => import("/home/ploi/app.kray.eu/pages/Login/index.vue")
  },
  {
    name: "Login-token",
    path: "/Login/token",
    meta: tokenQQmSy3Be8EMeta || {},
    component: () => import("/home/ploi/app.kray.eu/pages/Login/token.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout7uNl6FiZqdMeta || {},
    component: () => import("/home/ploi/app.kray.eu/pages/logout.vue")
  },
  {
    name: "PartnerCompany-id-edit",
    path: "/PartnerCompany/:id()/edit",
    component: () => import("/home/ploi/app.kray.eu/pages/PartnerCompany/[id]/edit.vue")
  },
  {
    name: "PartnerCompany-id",
    path: "/PartnerCompany/:id()",
    component: () => import("/home/ploi/app.kray.eu/pages/PartnerCompany/[id]/index.vue")
  },
  {
    name: "PartnerCompany-create",
    path: "/PartnerCompany/create",
    component: () => import("/home/ploi/app.kray.eu/pages/PartnerCompany/create.vue")
  },
  {
    name: "PartnerCompany",
    path: "/PartnerCompany",
    component: () => import("/home/ploi/app.kray.eu/pages/PartnerCompany/index.vue")
  },
  {
    name: "Product-id-contact",
    path: "/Product/:id()/contact",
    component: () => import("/home/ploi/app.kray.eu/pages/Product/[id]/contact/index.vue")
  },
  {
    name: "Product-id",
    path: "/Product/:id()",
    component: () => import("/home/ploi/app.kray.eu/pages/Product/[id]/index.vue")
  },
  {
    name: "Product-create",
    path: "/Product/create",
    component: () => import("/home/ploi/app.kray.eu/pages/Product/create.vue")
  },
  {
    name: "Product",
    path: "/Product",
    component: () => import("/home/ploi/app.kray.eu/pages/Product/index.vue")
  },
  {
    name: "Prospection",
    path: "/Prospection",
    component: () => import("/home/ploi/app.kray.eu/pages/Prospection/index.vue")
  },
  {
    name: "Settings",
    path: "/Settings",
    component: () => import("/home/ploi/app.kray.eu/pages/Settings/index.vue")
  },
  {
    name: "Task",
    path: "/Task",
    component: () => import("/home/ploi/app.kray.eu/pages/Task/index.vue")
  },
  {
    name: "Timesheet",
    path: "/Timesheet",
    component: () => import("/home/ploi/app.kray.eu/pages/Timesheet/index.vue")
  }
]