import HttpFactory from './Factory/HttpFactory'

class ContactRatingRepository extends HttpFactory {
    index (contactId, params = {}) {
        return this.$get(`contacts/${contactId}/rating`, params)
    }

    create (contactId, payload) {
        return this.$post(`contacts/${contactId}/rating`, payload)
    }

    update (contactId, contactRatingId, payload) {
        return this.$put(`contacts/${contactId}/rating/${contactRatingId}`, payload)
    }

    delete (contactId, contactRatingId) {
        return this.$delete(`contacts/${contactId}/rating/${contactRatingId}`)
    }
}

export default ContactRatingRepository
