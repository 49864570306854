import HttpFactory from './Factory/HttpFactory'

class ClientStepRepository extends HttpFactory {
    index (clientId, query = {}) {
        return this.$get(`clients/${clientId}/steps`, query)
    }

    create (clientId, payload) {
        return this.$post(`clients/${clientId}/steps`, payload)
    }
}

export default ClientStepRepository
