import HttpFactory from './Factory/HttpFactory'

class UserRepository extends HttpFactory {
    index (query) {
        return this.$get('users', query)
    }

    show (userId) {
        return this.$get(`users/${userId}`)
    }

    update (userId, payload) {
        return this.$put(`users/${userId}`, payload)
    }

    export (query = {}) {
        return this.$get('/users/export', query, {
            responseType: 'blob'
        })
    }

    delete (userId) {
        return this.$delete(`users/${userId}`)
    }

    getFolders (userId) {
        return this.$get(`users/${userId}/folders`)
    }

    getClientQuestions (userId) {
        return this.$get(`users/${userId}/client-questions`)
    }

    sendPasswordReset (userId) {
        return this.$post(`users/${userId}/password-reset`)
    }
}

export default UserRepository
