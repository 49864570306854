import validate from "/home/ploi/app.kray.eu/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/home/ploi/app.kray.eu/middleware/auth.global.ts";
import manifest_45route_45rule from "/home/ploi/app.kray.eu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/home/ploi/app.kray.eu/middleware/admin.js"),
  administrative: () => import("/home/ploi/app.kray.eu/middleware/administrative.js"),
  guest: () => import("/home/ploi/app.kray.eu/middleware/guest.ts"),
  manager: () => import("/home/ploi/app.kray.eu/middleware/manager.js"),
  "not-in-panic-mode": () => import("/home/ploi/app.kray.eu/middleware/notInPanicMode.js"),
  ops: () => import("/home/ploi/app.kray.eu/middleware/ops.js"),
  staff: () => import("/home/ploi/app.kray.eu/middleware/staff.js")
}