import HttpFactory from './Factory/HttpFactory'

class ProductRepository extends HttpFactory {
    show (productId) {
        return this.$get('products/' + productId)
    }

    index () {
        return this.$get('products')
    }

    create (payload) {
        return this.$post('products', payload)
    }

    update (productId, payload) {
        return this.$put(`products/${productId}`, payload)
    }

    delete (productId) {
        return this.$delete(`products/${productId}`)
    }

    attachContact (productId, payload) {
        return this.$post(`products/${productId}/contacts`, payload)
    }

    detachContact (productId, contactId) {
        return this.$delete(`products/${productId}/contacts/${contactId}`)
    }
}

export default ProductRepository
