import HttpFactory from './Factory/HttpFactory'

class SettingsRepository extends HttpFactory {
    index () {
        return this.$get('/settings')
    }

    uploadFile (file, collection) {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('collection', collection)

        return this.$post('/settings/file', formData)
    }
}

export default SettingsRepository
