import HttpFactory from './Factory/HttpFactory'

class FunderCompanyRepository extends HttpFactory {
    index (params = {}) {
        return this.$get('funder-companies', params)
    }

    show (funderCompanyId) {
        return this.$get('funder-companies/' + funderCompanyId)
    }

    create (payload) {
        return this.$post('funder-companies', payload)
    }

    update (funderCompanyId, payload) {
        return this.$put(`funder-companies/${funderCompanyId}`, payload)
    }

    delete (funderCompanyId) {
        return this.$delete(`funder-companies/${funderCompanyId}`)
    }

    attachContact (funderCompanyId, payload) {
        return this.$post(`funder-companies/${funderCompanyId}/contacts`, payload)
    }

    detachContact (funderCompanyId, contactId) {
        return this.$delete(`funder-companies/${funderCompanyId}/contacts/${contactId}`)
    }

    clients (funderCompanyId) {
        return this.$get(`funder-companies/${funderCompanyId}/clients`)
    }

    getMedias (funderCompanyId) {
        return this.$get(`funder-companies/${funderCompanyId}/medias`)
    }

    storeMedias (funderCompanyId, payload) {
        return this.$post(`funder-companies/${funderCompanyId}/medias`, payload)
    }

    exportContacts (query = {}) {
        return this.$get('funder-companies/contacts/export', query, {
            responseType: 'blob'
        })
    }
}

export default FunderCompanyRepository
