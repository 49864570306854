import HttpFactory from './Factory/HttpFactory'

class ContactRepository extends HttpFactory {
    index (params = {}) {
        return this.$get('contacts', params)
    }

    show (contactId) {
        return this.$get('contacts/' + contactId)
    }

    create (payload) {
        return this.$post('contacts', payload)
    }

    update (contactId, payload) {
        return this.$put(`contacts/${contactId}`, payload)
    }

    delete (contactId) {
        return this.$delete(`contacts/${contactId}`)
    }

    updateWorkflow (contactId, payload) {
        return this.$put(`contacts/${contactId}/workflow-update`, payload)
    }

    inviteUser (contactId) {
        return this.$post(`contacts/${contactId}/invite-user`)
    }

    getDeals (contactId) {
        return this.$get(`contacts/${contactId}/deals`)
    }

    export (query = {}) {
        return this.$get('/contacts/export', query, {
            responseType: 'blob'
        })
    }
}

export default ContactRepository
