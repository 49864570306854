import { defineStore } from 'pinia'
import { ofetch } from 'ofetch'
import { useRuntimeConfig, useCookie, useNuxtApp } from '#app'

export const useAuthStore = defineStore('auth', {
    state: () => {
        const defaultHeaders = {
            Accept: 'application/json',
            credentials: 'include'
        }

        return {
            authenticated: false,
            user: null,
            apiHeaders: defaultHeaders
        }
    },

    actions: {
        makeApiWrapper (apiRoute = true) {
            const runtimeConfig = useRuntimeConfig()

            const that = this

            if (useCookie('XSRF-TOKEN').value) {
                this.setApiHeader('X-XSRF-TOKEN', useCookie('XSRF-TOKEN').value)
            }

            return ofetch.create({
                baseURL: apiRoute ? runtimeConfig.public.apiUrl : runtimeConfig.public.appUrl,
                headers: this.apiHeaders,
                credentials: 'include',
                onRequest ({ options }) {
                    /**
                     * Fix Query Serialization
                     */
                    if (options.query) {
                        Object.entries(options.query).forEach(([key, val]) => {
                            if (typeof val === 'object' && Array.isArray(val) && val !== null) {
                                options.query[key + '[]'] = val.map(v => typeof v === 'object' ? JSON.stringify(v) : v)
                                delete options.query[key]
                            }
                            if (val === null) {
                                delete options.query[key]
                            }
                        })
                    }
                },
                onResponseError ({ response }) {
                    if (response._data) {
                        response.data = response._data
                    }

                    if (response.status && response.status === 401) {
                        that.logout(false)
                        // if (!window.location.pathname.includes('/login')) {
                        //     window.location = '/login'
                        // }
                    } else if (response.status && response.status === 503) {
                        window.location = '/maintenance'
                    }
                }
            })
        },

        setApiHeader (header, value) {
            this.apiHeaders[header] = value
        },

        login (payload) {
            const api = this.makeApiWrapper()

            return api('/login', {
                method: 'POST',
                body: payload
            })
        },

        fetchUser () {
            const api = this.makeApiWrapper()

            return api('/me').then((response) => {
                this.authenticated = true
                this.user = response.data
            })
        },

        fetchToken () {
            const api = this.makeApiWrapper(false)

            return api('/sanctum/csrf-cookie').then(() => {
                this.setApiHeader('X-XSRF-TOKEN', useCookie('XSRF-TOKEN').value)
            })
        },

        storeTwoFaCode (code) {
            const api = this.makeApiWrapper(false)

            return api('/auth/two-factor-challenge', {
                method: 'POST',
                body: {
                    code
                }
            })
        },

        logout (useApi = true) {
            const authCookie = useCookie('XSRF-TOKEN')
            authCookie.value = null
            this.authenticated = false
            this.user = null

            delete this.apiHeaders['X-XSRF-TOKEN']

            const api = this.makeApiWrapper(false)

            if (useApi) {
                return api('/api/logout', {
                    method: 'POST'
                })
                    .then(() => {
                        const { $router } = useNuxtApp()

                        setTimeout(() => {
                            $router.push('/login')
                        }, 500)
                    })
            }
            const { $router } = useNuxtApp()

            setTimeout(() => {
                $router.push('/login')
            }, 500)
        }
    },

    persist: true
})
