class HttpFactory {
    constructor (authStore, config) {
        this.auth = authStore
        this.config = config
    }

    fetch (url, options = {}) {
        const api = this.auth.makeApiWrapper()
        return api(url, options)
    }

    raw (url, options = {}) {
        const api = this.auth.makeApiWrapper()
        return api.raw(url, options)
    }

    $get (url, query = {}, options = {}) {
        return (options.download || options.responseType === 'blob')
            ? this.raw(url, { query, ...options, method: 'GET' })
            : this.fetch(url, { query, ...options, method: 'GET' })
    }

    $post (url, body = {}, options = {}) {
        return (options.download || options.responseType === 'blob')
            ? this.raw(url, { body, ...options, method: 'POST' })
            : this.fetch(url, { body, ...options, method: 'POST' })
    }

    $patch (url, body = {}, options = {}) {
        return this.fetch(url, { body, ...options, method: 'PATCH' })
    }

    $put (url, body = {}, options = {}) {
        return this.fetch(url, { body, ...options, method: 'PUT' })
    }

    $delete (url, query = {}, options = {}) {
        return this.fetch(url, { query, ...options, method: 'DELETE' })
    }
}

export default HttpFactory
