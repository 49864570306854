import HttpFactory from './Factory/HttpFactory'

class MediaRepository extends HttpFactory {
    changeFolder (mediaId, folderId) {
        return this.$put(`medias/${mediaId}/to-folder/${folderId}`)
    }

    delete (mediaId, params = {}) {
        return this.$delete(`medias/${mediaId}`, params)
    }

    download (mediaId) {
        return this.fetch(`/medias/${mediaId}/download`, {
            method: 'get',
            responseType: 'blob'
        })
    }
}

export default MediaRepository
