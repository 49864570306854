import HttpFactory from './Factory/HttpFactory'

class BankRepository extends HttpFactory {
    index (query = {}) {
        return this.$get('banks', query)
    }

    show (bankId) {
        return this.$get('banks/' + bankId)
    }

    create (payload) {
        return this.fetch('banks', {
            method: 'post',
            body: payload
        })
    }

    update (bankId, payload) {
        return this.fetch(`banks/${bankId}`, {
            method: 'patch',
            body: payload
        })
    }

    delete (bankId) {
        return this.fetch(`banks/${bankId}`, {
            method: 'delete'
        })
    }

    attachContact (bankId, payload) {
        return this.fetch(`banks/${bankId}/contacts`, {
            method: 'post',
            body: payload
        })
    }

    detachContact (bankId, contactId) {
        return this.fetch(`banks/${bankId}/contacts/${contactId}`, {
            method: 'delete'
        })
    }
}

export default BankRepository
