import HttpFactory from './Factory/HttpFactory'

class ActivityRepository extends HttpFactory {
    index (query = {}) {
        return this.$get('feed', query)
    }

    getForClient (clientId) {
        return this.$get(`feed/clients/${clientId}`)
    }

    exportForClient (clientId) {
        return this.$post(`/feed/clients/${clientId}/export`, {
            method: 'post',
            responseType: 'blob'
        })
    }

    getForFunderCompany (funderCompanyId) {
        return this.$get(`feed/funder-companies/${funderCompanyId}`)
    }

    getForPartnerCompany (partnerCompanyId) {
        return this.$get(`feed/partner-companies/${partnerCompanyId}`)
    }

    getForBroker (brokerId) {
        return this.$get(`feed/brokers/${brokerId}`)
    }
}

export default ActivityRepository
