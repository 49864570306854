import HttpFactory from './Factory/HttpFactory'

class TaskRepository extends HttpFactory {
    index (params = {}) {
        return this.$get('tasks', params)
    }

    show (taskId) {
        return this.$get('tasks/' + taskId)
    }

    create (payload) {
        return this.$post('tasks', payload)
    }

    update (taskId, payload) {
        return this.$put(`tasks/${taskId}`, payload)
    }

    delete (taskId) {
        return this.$delete(`tasks/${taskId}`)
    }

    getForContact (contactId, params = {}) {
        return this.$get(`/contacts/${contactId}/tasks`, params)
    }

    createForContact (contactId, payload) {
        return this.$post(`/contacts/${contactId}/tasks`, payload)
    }
}

export default TaskRepository
