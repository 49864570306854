import HttpFactory from './Factory/HttpFactory'

class PartnerCompanyRepository extends HttpFactory {
    index (query = {}) {
        return this.$get('partner-companies', query)
    }

    show (partnerCompanyId) {
        return this.$get('partner-companies/' + partnerCompanyId)
    }

    create (payload) {
        return this.$post('partner-companies', payload)
    }

    update (partnerCompanyId, payload) {
        return this.$put(`partner-companies/${partnerCompanyId}`, payload)
    }

    delete (partnerCompanyId) {
        return this.$delete(`partner-companies/${partnerCompanyId}`)
    }

    attachContact (partnerCompanyId, payload) {
        return this.$post(`partner-companies/${partnerCompanyId}/contacts`, payload)
    }

    detachContact (partnerCompanyId, contactId) {
        return this.$delete(`partner-companies/${partnerCompanyId}/contacts/${contactId}`)
    }

    exportContacts (params = {}) {
        return this.fetch('partner-companies/contacts/export', {
            method: 'get',
            params,
            responseType: 'blob'
        })
    }
}

export default PartnerCompanyRepository
